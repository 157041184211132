


































































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { DialogLink, DialogQuestion } from '@/store/popupSearchBot'
import Link from '@/components/Link.vue'
import { SearchBotQuestionAnswerResult } from '@/contracts/api/QuerySearchBot/QuerySearchBot.Response'
import BlockText from '@/components/BlockText.vue'

export default defineComponent({
  name: 'SearchAnswer',
  components: { BlockText, Link },
  props: {
    text: {
      type: String,
      default: '',
    },
    questionList: {
      type: Array as PropType<DialogQuestion[]>,
      default: () => [],
    },
    links: {
      type: Array as PropType<DialogLink[]>,
      default: () => [],
    },
    result: {
      type: Object as PropType<SearchBotQuestionAnswerResult>,
      default: () => ({}),
    },
  },
  setup(_, { emit }) {
    const onSelectQuestion = (question: DialogQuestion) => {
      emit('select-question', question)
    }

    return {
      onSelectQuestion,
    }
  },
})
