import { SearchBotQuestionAnswerResult } from '../contracts/api/QuerySearchBot/QuerySearchBot.Response'

export type DialogQuestion = {
  id: string
  name: string
  result?: SearchBotQuestionAnswerResult
}

export type DialogLink = {
  id: string
  url: string
  title: string
}

export type DialogItem = {
  id: string
  type: 'answer' | 'question'
  title: string
  questions?: DialogQuestion[]
  links?: DialogLink[]
  result?: SearchBotQuestionAnswerResult
}

export const state = () => {
  return {
    dialogList: [] as DialogItem[],
    isShow: false,
  }
}

export const getters = {
  getIsShown: (state) => state.isShow,
  getDialogList: (state) => state.dialogList,
}

export const mutations = {
  SET_IS_SHOWN(state, value: boolean) {
    state.isShow = value
  },
  SET_DIALOG_ITEM(state, item: DialogItem) {
    state.dialogList.push(item)
  },
}
