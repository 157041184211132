export const state = () => ({
  metricsData: {
    путь: {
      первый_поиск: null,
      второй_поиск: null,
      первая_кнопка: null,
      вторая_кнопка: null,
    },
  },
})

export const mutations = {
  SET_SEARCH_ONE(state, searchOne) {
    if (state.metricsData.путь.первый_поиск === null) {
      state.metricsData.путь.первый_поиск = searchOne
    }
  },
  SET_SEARCH_TWO(state, searchTwo) {
    if (state.metricsData.путь.второй_поиск === null) {
      state.metricsData.путь.второй_поиск = searchTwo
    }
  },
  SET_FIRST_BTN(state, firstBtn) {
    if (state.metricsData.путь.первая_кнопка === null) {
      state.metricsData.путь.первая_кнопка = firstBtn
    }
  },
  SET_SECOND_BTN(state, secondBtn) {
    if (state.metricsData.путь.вторая_кнопка === null) {
      state.metricsData.путь.вторая_кнопка = secondBtn
    }
  },

  RESET_METRICS_DATA(state) {
    state.metricsData.путь = {
      первый_поиск: null,
      второй_поиск: null,
      первая_кнопка: null,
      вторая_кнопка: null,
    }
  },
}

export const actions = {
  sendMetrics({ state, commit }) {
    // Отправка данных в метрику
    window.ym(99530371, 'reachGoal', 'search_way', state.metricsData)
    // Сброс данных после отправки
    // commit('RESET_METRICS_DATA')
  },
}
