// @ts-nocheck
import { buttonsAdapter } from "./buttonsAdapter";

export const sectionDataAdapter = (data: any) => {
  const {
    sectionMainTitle,
    sectionSideTitle,
    sectionId,
    sectionDescription,
    sectionDate,
    sectionButtonsAlignment,
    sectionButtons,
    linkText,
    linkUrl,
    sectionBackground,
    offsetDesktop = null,
    offsetMobile = null,
  } = data

  return {
    sectionMainTitle,
    sectionSideTitle,
    sectionId,
    sectionDescription,
    sectionDate,
    sectionButtonsAlignment,
    sectionBackground,
    sectionButtons: buttonsAdapter(sectionButtons),
    sectionLink: linkText
      ? {
        value: linkText,
        link: linkUrl || '#',
        arrowType: 'arrow-right',
      }
    : null,
    offset: {
      desktop: offsetDesktop ? /^\d+,\d+$/.test(offsetDesktop) && offsetDesktop.split(',').map(Number) : null,
      mobile: offsetMobile ? /^\d+,\d+$/.test(offsetMobile) && offsetMobile.split(',').map(Number) : null,
    },
  }
}
