

















































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useStore,
} from '@nuxtjs/composition-api'
import { computed, nextTick } from '@vue/composition-api'
import Popup from '@/packages/basis/components/Popup.vue'
import SearchInput from '@/components/SearchPopup/SearchInput.vue'
import SearchAnswer from '@/components/SearchPopup/SearchAnswer.vue'
import SearchQuestion from '@/components/SearchPopup/SearchQuestion.vue'
import { Logger } from '@/composition/Logger'
import { DialogQuestion } from '@/store/popupSearchBot'
import { createYaSearchBot } from '@/composition/ym'

export default defineComponent({
  name: 'SearchPopup',
  components: { SearchQuestion, SearchAnswer, SearchInput, Popup },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const store = useStore()
    const ctx = useContext()
    const { yaSearchBot } = createYaSearchBot()

    const search = ref()
    const contentElement = ref<HTMLDivElement>()

    const dialogList = computed(
      () => store.getters['popupSearchBot/getDialogList']
    )

    const sendMetrics = () => {
      store.dispatch('metricsBot/sendMetrics')
    }

    const hideSearchPopup = () => {
      emit('hide-popup')
      store.dispatch('metricsBot/sendMetrics')
    }

    const onInput = (value: string) => {
      search.value = value
    }

    const handleScroll = () => {
      nextTick(() => {
        contentElement.value?.scroll({
          top: contentElement.value.scrollHeight,
          behavior: 'smooth',
        })
      })
    }

    const onSubmit = async () => {
      if (!search.value) return

      store.commit('popupSearchBot/SET_DIALOG_ITEM', {
        type: 'question',
        title: search.value,
      })

      handleScroll()
      try {
        const searchResponse = await ctx.$api.requests.getSearchBotData(
          search.value
        )

        const searchData = Object.entries(searchResponse)
          .filter(([key]) => key !== 'status')
          .map(([_, value]) => value)

        if (searchData.length === 1) {
          const questionResponse =
            await ctx.$api.requests.getSearchBotNextQuestion(+searchData[0].id)

          if (questionResponse?.answers?.nextQuestion?.length < 1) {
            store.commit('popupSearchBot/SET_DIALOG_ITEM', {
              type: 'answer',
              title: questionResponse.title,
              result: {
                important: {
                  title:
                    questionResponse.answers.title || questionResponse.title,
                  preview: questionResponse.answers.result,
                },
              },
            })

            yaSearchBot('search_popup', {
              поисковая_фраза_два: search.value,
              полученный_ответ_два: {
                title: questionResponse.title,
                result: {
                  title:
                    questionResponse.answers.title || questionResponse.title,
                  preview: questionResponse.answers.result,
                },
              },
            })

            store.commit('metricsBot/SET_SEARCH_TWO', {
              поисковая_фраза_два: search.value,
              полученный_ответ_два: {
                title: questionResponse.title,
                result: {
                  title:
                    questionResponse.answers.title || questionResponse.title,
                  preview: questionResponse.answers.result,
                },
              },
            })
          } else if (questionResponse?.answers?.nextQuestion?.length > 1) {
            store.commit('popupSearchBot/SET_DIALOG_ITEM', {
              type: 'answer',
              title: 'Вот, что мне удалось найти:',
              questions: searchData.map((quest) => ({
                id: quest.id,
                name: quest.title,
              })),
              links: [],
            })
            store.commit('metricsBot/SET_SEARCH_TWO', {
              поисковая_фраза_два: search.value,
              полученный_ответ_два: {
                title: questionResponse.data.answers[0].result.title,
                result: { ...questionResponse.data.answers[0].result },
              },
            })
          } else if (questionResponse?.answers?.nextQuestion?.length > 1) {
            store.commit('popupSearchBot/SET_DIALOG_ITEM', {
              type: 'answer',
              title: 'Вот, что мне удалось найти:',
              questions: searchData.map((quest) => ({
                id: quest.id,
                name: quest.title,
              })),
              links: [],
            })

            store.commit('metricsBot/SET_SEARCH_TWO', {
              поисковая_фраза_два: search.value,
              полученный_ответ_два: searchData.map((quest) => ({
                id: quest.id,
                name: quest.title,
              })),
            })

            yaSearchBot('search_popup', {
              поисковая_фраза_два: search.value,
              полученный_ответ_два: searchData.map((quest) => ({
                id: quest.id,
                name: quest.title,
              })),
            })
          }
        } else {
          store.commit('popupSearchBot/SET_DIALOG_ITEM', {
            type: 'answer',
            title:
              'К сожалению, мне ничего не удалось найти :( Пожалуйста, переформулируйте вопрос, или оставьте обращение в форме обратной связи <a style="color: white; text-decoration: underline" href="/feedback">https://www.rgs.ru/feedback</a>',
          })
          yaSearchBot('search_popup', {
            поисковая_фраза_два: search.value,
            полученный_ответ_два: `К сожалению, мне ничего не удалось найти :( Пожалуйста, переформулируйте вопрос, или оставьте обращение в форме обратной связи <a style="color: white; text-decoration: underline" href="/feedback">https://www.rgs.ru/feedback</a>`,
          })
          store.commit('metricsBot/SET_SEARCH_TWO', {
            поисковая_фраза_два: search.value,
            полученный_ответ_два: `К сожалению, мне ничего не удалось найти :( Пожалуйста, переформулируйте вопрос, или оставьте обращение в форме обратной связи <a style="color: white; text-decoration: underline" href="/feedback">https://www.rgs.ru/feedback</a>`,
          })
        }
        search.value = ''
      } catch (e) {
        Logger.error(e)
      }
      handleScroll()
    }

    const onSelectQuestion = async (question: DialogQuestion) => {
      store.commit('popupSearchBot/SET_DIALOG_ITEM', {
        type: 'question',
        title: question.name,
      })

      store.commit('metricsBot/SET_FIRST_BTN', {
        выбрана_кнопка: question.name,
      })

      yaSearchBot('search_button_first', {
        выбрана_кнопка: question.name,
      })
      handleScroll()

      if (question.id === '0') {
        store.commit('popupSearchBot/SET_DIALOG_ITEM', {
          type: 'answer',
          title: question.result.title,
          result: {
            ...question.result,
          },
        })
        yaSearchBot('search_button_second', {
          выбрана_кнопка: {
            вопрос: question.result.title,
            ответ: {
              ...question.result,
            },
          },
        })
        store.commit('metricsBot/SET_SECOND_BTN', {
          вопрос: question.result.title,
          ответ: {
            ...question.result,
          },
        })
        handleScroll()
        return
      }

      try {
        const searchResponse = await ctx.$api.requests.getSearchBotNextQuestion(
          +question.id
        )

        if (searchResponse.answers.nextQuestion.length < 1) {
          store.commit('popupSearchBot/SET_DIALOG_ITEM', {
            type: 'answer',
            title: searchResponse.answers.title || searchResponse.title,
            result: {
              important: {
                title: searchResponse.answers.title || searchResponse.title,
                preview: searchResponse.answers.result,
              },
            },
          })
          store.commit('metricsBot/SET_SECOND_BTN', {
            вопрос: searchResponse.answers.title || searchResponse.title,
            ответ: {
              title: searchResponse.answers.title || searchResponse.title,
              preview: searchResponse.answers.result,
            },
          })
          yaSearchBot('search_button_second', {
            поиск_по_второй_кнопке: {
              title: searchResponse.answers.title || searchResponse.title,
              preview: searchResponse.answers.result,
            },
          })
          handleScroll()
          return
        } else if (searchResponse.answers.nextQuestion.length > 1) {
          const nextQuestionArr = []
          for await (const answer of searchResponse.answers.nextQuestion) {
            const nextQuestionRes =
              await ctx.$api.requests.getSearchBotNextQuestion(answer)
            nextQuestionArr.push(nextQuestionRes)
          }
          store.commit('popupSearchBot/SET_DIALOG_ITEM', {
            id: searchResponse.id,
            type: 'answer',
            title: searchResponse.title,
            questions: nextQuestionArr.map((quest) => ({
              id: quest.id,
              name: quest.title,
              result: quest.result,
            })),
          })
          store.commit('metricsBot/SET_SECOND_BTN', {
            поиск_по_второй_кнопке: searchResponse.title,
            ответ_по_второй_кнопке: nextQuestionArr.map((quest) => ({
              name: quest.title,
              result: quest.result,
            })),
          })

          yaSearchBot('search_button_second', {
            поиск_по_второй_кнопке: question.name,
            ответ_по_второй_кнопке: nextQuestionArr.map((quest) => ({
              name: quest.title,
              result: quest.result,
            })),
          })
        }

        // store.commit('popupSearchBot/SET_DIALOG_ITEM', {
        //   id: searchResponse.data.id,
        //   type: 'answer',
        //   title: searchResponse.data.title,
        //   questions: searchResponse.data.answers.map((quest) => ({
        //     id: quest.question,
        //     name: quest.name,
        //     result: quest.result,
        //   })),
        // })

        handleScroll()
      } catch (e) {
        Logger.error(e)
      }
    }

    onMounted(handleScroll)

    return {
      contentElement,
      search,
      dialogList,
      hideSearchPopup,
      onSubmit,
      onInput,
      onSelectQuestion,
    }
  },
})
