import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { PropType } from '@nuxtjs/composition-api'
import { SectionData, emptySectionData } from '@/contracts/SectionData'

@Component
export class SectionCore extends Vue {
  @Prop({ default: '' }) readonly title: string = ''
  @Prop({ default: '' }) readonly titleText: string = ''
  @Prop({ default: '' }) readonly titleCards?: string = ''
  @Prop({ default: '' }) readonly subtitle: string = ''
  @Prop({ default: '' }) readonly formTitle: string = ''
  @Prop({ default: '' }) readonly text: string = ''
  @Prop({ default: '' }) readonly textData: string = ''
  @Prop({ default: '' }) readonly textPreview: string = ''
  @Prop({ default: '' }) readonly textToOpen: string = ''
  @Prop({ default: '' }) readonly textProgressBar: string = ''
  @Prop({ default: '' }) readonly lastModified: string = ''
  @Prop({ default: '' }) readonly idRecipients: string = ''
  @Prop({ default: '' }) readonly idRegionRecipients: string = ''
  @Prop({ default: '' }) readonly privacyTitle: string = ''
  @Prop({ default: '' }) readonly privacyLinkUrl: string = ''
  @Prop({ default: '' }) readonly privacyLinkLabel: string = ''
  @Prop({ default: '' }) readonly successMessage: string = ''
  @Prop({ default: '' }) readonly iframeData: string = ''
  @Prop({ default: '' }) readonly offsetMobile: string = ''
  @Prop({ default: '' }) readonly offsetDesktop: string = ''
  @Prop({ default: false }) readonly withTabs: Boolean = false
  @Prop({ default: false }) readonly needBreadCrumbs: Boolean = false
  @Prop({ default: false }) readonly isDarkTheme: boolean = false
  @Prop({ default: false }) readonly isExtended: boolean = false
  @Prop({ default: false }) readonly isList: Boolean = false
  @Prop({ default: false }) readonly titleNoMargin: boolean = false
  @Prop({ default: () => emptySectionData() })
  readonly sectionData: SectionData = emptySectionData()

  @Prop({ default: () => {} }) readonly sectionId: Object = {}
  @Prop({ default: () => [] }) readonly faqData: ReadonlyArray<any> = []
  static options: any
}

export const sectionCoreProps = {
  props: {
    title: {
      type: String,
      default: '',
    },
    titleText: {
      type: String,
      default: '',
    },
    titleCards: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    formTitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    textData: {
      type: String,
      default: '',
    },
    textPreview: {
      type: String,
      default: '',
    },
    textToOpen: {
      type: String,
      default: '',
    },
    textProgressBar: {
      type: String,
      default: '',
    },
    lastModified: {
      type: String,
      default: '',
    },
    idRecipients: {
      type: String,
      default: '',
    },
    successMessage: {
      type: String,
      default: '',
    },
    iframeData: {
      type: String,
      default: '',
    },
    indentMobile: {
      type: String,
      default: '',
    },
    indentDesktop: {
      type: String,
      default: '',
    },
    withTabs: {
      type: Boolean,
      default: false,
    },
    needBreadCrumbs: {
      type: Boolean,
      default: false,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    privacyTitle: {
      type: String,
      default: '',
    },
    privacyLinkUrl: {
      type: String,
      default: '',
    },
    privacyLinkLabel: {
      type: String,
      default: '',
    },
    isExtended: {
      type: Boolean,
      default: false,
    },
    isList: {
      type: Boolean,
      default: false,
    },
    titleNoMargin: {
      type: Boolean,
      default: false,
    },
    sectionData: {
      type: Object as PropType<SectionData>,
      default: () => emptySectionData(),
    },
    sectionId: {
      type: Object as PropType<any>,
      default: () => {},
    },
    faqData: {
      type: Array as PropType<Array<any>>,
      default: () => [] as any[],
    },
  },
}
