










































































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { getColorTitle } from '@/composition/coloredTitle'
import { SectionData, emptySectionData } from '@/contracts/SectionData'
import BlockSectionTags from '@/components/BlockSectionTags.vue'
import Link from '@/components/Link.vue'
import Loader from '@/plugins/loader/Load.vue'
import BlockButtons from '@/components/BlockButtons.vue'

export default defineComponent({
  name: 'SectionBasic',
  components: { BlockButtons, BlockSectionTags, Link, Loader },
  props: {
    sectionData: {
      type: Object as PropType<SectionData>,
      default: () => emptySectionData(),
    },
    titleNoMargin: {
      type: Boolean,
      default: false,
    },
    coverEagle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const styles = computed(() => ({
      '--section-background': props.sectionData.sectionBackground,
    }))
    return {
      styles,
      getColorTitle,
    }
  },
})
