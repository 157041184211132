


































import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const input = ref<HTMLInputElement | null>(null)
    const onInput = (event: InputEvent) => {
      const target = event.target as HTMLInputElement
      emit('input', target.value)
    }

    const onSubmit = () => {
      input.value.value = ''
      emit('submit')
    }

    return {
      input,
      onInput,
      onSubmit,
    }
  },
})
