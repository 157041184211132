import Base from './_base'
import request from './request'
import {
  API_ROUTES_CHECK_DOCUMENTS,
  API_ROUTES_GET_VEHICLE_MODELS,
  API_ROUTES_GET_CITIES,
  API_ROUTES_CHECK_AGENT,
  API_ROUTES_CHECK_BSO,
  API_ROUTES_GET_OFFICES,
  API_ROUTES_GET_OFFICES_BY_BOUNDS,
  API_ROUTES_CHECK_STATUS,
  API_ROUTES_GET_STOAS,
  API_ROUTES_GET_REGIONS,
  API_ROUTES_REGISTER_CLAIM,
  API_ROUTES_SEND_CONFIRM_EMAIL_APPEAL,
  API_ROUTES_SEND_DEBTOR,
  API_ROUTES_SEND_APPEAL,
  API_ROUTES_GET_APPEAL,
  API_ROUTES_SEND_SMS,
  API_ROUTES_SEND_SPORT_INSURANCE_CASE,
  API_ROUTES_GET_INSURANCE_PERIODS,
  API_ROUTES_FEEDBACK_AGENTS,
  API_SEARCH_BOT_SEARCHING,
  API_SEARCH_NEXT_QUESTION,
} from '@/config/constants'
import { QueryRegionRequest } from '@/contracts/api/QueryRegion/QueryRegion.Request'
import { QueryRegionResponse } from '@/contracts/api/QueryRegion/QueryRegion.Response'
import { ApiReturn } from '@/contracts/api/RequestReturn'
import { QueryCitiesRequest } from '@/contracts/api/QueryCites/QueryCities.Request'
import { QueryCitiesResponse } from '@/contracts/api/QueryCites/QueryCities.Response'
import { QueryOfficesResponse } from '@/contracts/api/QueryOffices/QueryOffices.Response'
import { QueryOfficeRequest } from '@/contracts/api/QueryOffices/QueryOffice.Request'
import { RegisterClaimResponse } from '@/contracts/api/RegisterClaim/RegisterClaimResponse'
import { BackendModel } from '@/contracts/AppealModel'
import { RegisterClaimRequest } from '@/contracts/api/RegisterClaim/RegisterClaim.Request'
import { QueryInstancePeriodResponse } from '@/contracts/api/QueryInsuracePeriods/QueryInsurancePeriod.Response'
import { FeedbackAgentsPayload } from '@/contracts/FeedbackAgents'
import {
  SearchBotDataResponse,
  SearchBotQuestion,
  SearchBotQuestionResponse,
} from '@/contracts/api/QuerySearchBot/QuerySearchBot.Response'

export default class Auto extends Base {
  /**
   * Получение списка регионов
   * @param {?QueryRegionRequest} requestPayload
   * @return ApiReturn<QueryRegionResponse>
   */
  async getRegions(requestPayload: QueryRegionRequest = {}) {
    const defaultPayload: QueryCitiesRequest = {
      page: 1,
    }

    const params = {
      ...defaultPayload,
      ...requestPayload,
    }

    return {
      data: await request<QueryRegionResponse>(
        this.$ctx,
        'get',
        API_ROUTES_GET_REGIONS,
        params
      ),
    }
  }

  // ЗАПРОС СПИСКА ГОРОДОВ В ВЫБРАННОМ РЕГИОНЕ
  async getCities(requestPayload: QueryCitiesRequest = {}) {
    const defaultPayload: QueryCitiesRequest = {
      page: 1,
    }

    const params = {
      ...defaultPayload,
      ...requestPayload,
    }

    return {
      data: await request<QueryCitiesResponse>(
        this.$ctx,
        'get',
        API_ROUTES_GET_CITIES,
        params
      ),
    }
  }

  // ЗАПРОС СПИСКА ОФИСОВ ПО ГОРОДАМ РЕГИОНА
  async getOffices(requestPayload: QueryOfficeRequest) {
    const defaultPayload: QueryCitiesRequest = {
      page: 1,
    }

    const params = {
      ...defaultPayload,
      ...requestPayload,
    }

    return {
      data: await request<QueryOfficesResponse>(
        this.$ctx,
        'get',
        API_ROUTES_GET_OFFICES,
        params
      ),
    }
  }

  async getSearchBotData(text: string) {
    return await request<SearchBotQuestion[]>(
      this.$ctx,
      'get',
      API_SEARCH_BOT_SEARCHING,
      { text }
    )
  }

  async getSearchBotNextQuestion(questionId: number) {
    return await request<SearchBotQuestionResponse>(
      this.$ctx,
      'get',
      API_SEARCH_NEXT_QUESTION + `${questionId}`,
      {}
    )
  }

  // ЗАПРОС СПИСКА ОФИСОВ ПО ОБЛАСТИ ВИДИМОСТИ КАРТЫ
  async getOfficesByBounds(params: any) {
    return {
      data: await request(
        this.$ctx,
        'get',
        API_ROUTES_GET_OFFICES_BY_BOUNDS,
        params,
        {}
      ),
    }
  }

  async getVehicleModels(search: string): Promise<any> {
    const params = {
      name: search,
    }
    return {
      data: await request(
        this.$ctx,
        'get',
        API_ROUTES_GET_VEHICLE_MODELS,
        params
      ),
    }
  }

  async checkDocuments(params: { email: string }): Promise<any> {
    return {
      data: await request(
        this.$ctx,
        'post',
        API_ROUTES_CHECK_DOCUMENTS,
        params
      ),
    }
  }

  async checkAgent(params: {
    name: string
    inn: string
    url: string
    docNumber: string
    isNew?: boolean
  }): Promise<any> {
    // Удаляем пустые поля из параметров
    Object.keys(params).forEach(
      (k) =>
        params[k as keyof Object] === null && delete params[k as keyof Object]
    )

    return {
      data: await request(this.$ctx, 'post', API_ROUTES_CHECK_AGENT, params),
    }
  }

  async checkStatus(params: {
    lastName: string
    number: number
  }): Promise<any> {
    return {
      data: await request(this.$ctx, 'post', API_ROUTES_CHECK_STATUS, params),
    }
  }

  async checkBso(params: { series: string; number: string }): Promise<any> {
    return {
      data: await request(this.$ctx, 'post', API_ROUTES_CHECK_BSO, params),
    }
  }

  async feedbackAgents(params: FeedbackAgentsPayload) {
    return await request(this.$ctx, 'post', API_ROUTES_FEEDBACK_AGENTS, params)
  }

  // ОТПРАВКА УВЕДОМЛЕНИЙ ПО СТРАХОВОМУ СЛУЧАЮ КАСКО ИЛИ ОСАГО
  async registerClaim(params: RegisterClaimRequest) {
    return {
      data: await request<RegisterClaimResponse>(
        this.$ctx,
        'post',
        API_ROUTES_REGISTER_CLAIM,
        params
      ),
    }
  }

  // ЗАПРОС СПИСКА СТОА
  async getStoas(
    page: number,
    perPage: number,
    city: string,
    sort: string
  ): Promise<any> {
    const params = {
      page,
      perPage,
      city,
      sort,
    }

    return {
      data: await request(this.$ctx, 'get', API_ROUTES_GET_STOAS, params),
    }
  }

  async getInsurancePeriodInfo(year: number): Promise<{
    data: QueryInstancePeriodResponse
  }> {
    return {
      data: await request<QueryInstancePeriodResponse>(
        this.$ctx,
        'get',
        API_ROUTES_GET_INSURANCE_PERIODS,
        {
          year,
        }
      ),
    }
  }

  // ЗАПРОС СПИСКА СТОА
  async getAppeal(GUID: string): Promise<any> {
    return {
      data: await request(this.$ctx, 'get', API_ROUTES_GET_APPEAL, { GUID }),
    }
  }

  // ЗАПРОС СПИСКА СТОА
  async sendEmailConfirmAppeal(email: string): Promise<any> {
    const params = {
      email,
    }
    return {
      data: await request(
        this.$ctx,
        'post',
        API_ROUTES_SEND_CONFIRM_EMAIL_APPEAL,
        params
      ),
    }
  }

  // ЗАПРОС СПИСКА СТОА
  async sendAppeal(model: BackendModel): Promise<any> {
    console.log('async sendAppeal model:', model)
    return {
      data: await request(this.$ctx, 'post', API_ROUTES_SEND_APPEAL, model),
    }
  }

  async sendInsuranceCaseForm(model: FormData): Promise<any> {
    return {
      data: await request(
        this.$ctx,
        'post',
        API_ROUTES_SEND_SPORT_INSURANCE_CASE,
        model
      ),
    }
  }

  // Отправка обращения должников
  async sendDebtor(params: FormData): Promise<any> {
    return {
      data: await request(this.$ctx, 'post', API_ROUTES_SEND_DEBTOR, params),
    }
  }

  // Отправка обращения должников
  async sendSms(
    name: string,
    phone: string,
    agentPhone: string,
    time: string /* token: string */
  ): Promise<any> {
    return {
      data: await request(this.$ctx, 'post', API_ROUTES_SEND_SMS, {
        name,
        phone,
        agentPhone,
        time,
        // token
      }),
    }
  }
}
